<template>
	<div>
		<b-card title="Payment time period">
			<validation-observer>
				<b-row>
					<b-col md="6" cols="12">
						<b-form-group>
							<label>From date</label>
							<validation-provider #default="{ errors }" name="From date" rules="required">
								<b-input-group>
									<b-form-input v-model="formData.from_date" type="text" autocomplete="off" show-decade-nav />
									<b-input-group-append>
										<b-form-datepicker
											v-model="formData.from_date"
											show-decade-nav
											button-only
											right
											locale="en-US"
											@context="onContext"
										/>
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="6" cols="12">
						<b-form-group>
							<label>To date</label>
							<validation-provider #default="{ errors }" name="To date" rules="required">
								<b-input-group>
									<b-form-input v-model="formData.to_date" type="text" autocomplete="off" show-decade-nav />
									<b-input-group-append>
										<b-form-datepicker
											v-model="formData.to_date"
											show-decade-nav
											button-only
											right
											locale="en-US"
											@context="onContext"
										/>
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col class="d-flex justify-content-end align-items-center mt-1">
						<p v-show="!validRange" class="text-danger mb-0 mr-2">Sorry, Invalid date range!</p>
						<loading-btn variant="primary" :loading="loading" :disabled="!validRange" @click="fetchMembers">
							Get Workers
						</loading-btn>
					</b-col>
				</b-row>
			</validation-observer>
		</b-card>
		<b-card title="Workers to pay">
			<div v-if="!loading" class="">
				<template v-if="workers.length">
					<p v-for="(w, i) in workers" :key="i">{{ w }}</p>
				</template>
				<div v-else class="text-muted text-center mt-3">
					<feather-icon icon="AlertCircleIcon" size="36" />
					<p class="mt-1">
						There is no workers in this time period!
					</p>
				</div>
			</div>
			<div v-else class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BCard, BRow, BCol, BSpinner, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import LoadingBtn from '@/components/LoadingBtn.vue';

export default {
	name: 'NewPayment',

	components: {
		BCard,
		BRow,
		BCol,
		BSpinner,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
		BFormDatepicker,
		ValidationProvider,
		ValidationObserver,

		LoadingBtn
	},

	data: () => ({
		required,
		formData: {
			from_date: '',
			to_date: '',
			members: []
		},

		workers: [],

		formatted: '',
		selected: '',
		loading: false
	}),

	computed: {
		validRange() {
			if (!!this.formData.from_date && !!this.formData.to_date) {
				const fromDate = new Date(this.formData.from_date);
				const toDate = new Date(this.formData.to_date);
				if (fromDate < toDate) {
					return true;
				}

				return false;
			}

			return false;
		}
	},

	methods: {
		onContext(ctx) {
			this.formatted = ctx.selectedFormatted;
			this.selected = ctx.selectedYMD;
		},

		async fetchMembers() {
			try {
				this.loading = true;
				this.workers = await store.dispatch('workers/getMembers', {
					id: this.$route.params.subId,
					fromDate: this.formData.from_date,
					toDate: this.formData.to_date
				});
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss">
.b-form-datepicker .btn {
	padding: 0.65rem 1rem;
	border-radius: 0 4px 4px 0;
}
</style>
