<template>
	<b-button :disabled="loading" v-bind="$attrs" v-on="$listeners">
		<b-spinner v-show="loading" class="align-middle" style="width: 1rem; height: 1rem;"></b-spinner>
		<span v-show="!loading"><slot /></span>
	</b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';

export default {
	name: 'LoadingBtn',

	components: { BButton, BSpinner },

	inheritAttrs: false,

	props: {
		loading: {
			type: Boolean
		}
	}
};
</script>

<style lang="scss" scoped></style>
